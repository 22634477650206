import React from 'react'
import { UserAuth } from '../contexts/AuthContext'
import Icon from './Icon'

function LogoutButton() {
  const { logout, user } = UserAuth()

  return (
    user && (
      <span className="navbar-item is-desktop-icon-only">
        <button
          type="button"
          onClick={() => logout({ returnTo: window.location.origin })}
          title="Log out"
          className="button is-ghost"
        >
          <Icon icon="ic:baseline-log-out" />
          <span>Log out</span>
        </button>
      </span>
    )
  )
}

export default LogoutButton
