import { useHistory } from 'react-router-dom'
import useToastContext from '../hooks/useToastContext'
import save, { get } from '../hooks/authenticated-fetch'
import useUserService from './useUserService'

const usePersonApi = () => {
  const history = useHistory()
  const addToast = useToastContext()
  const { getAccessToken } = useUserService()

  const getOpenItemsForPerson = async () => {
    return get(
      `${process.env.REACT_APP_API_URI}v1/person/me/open-items`,
      getAccessToken,
      null,
      null,
      null,
      history
    )
  }

  // ignoredLabels is unused, but for consistency between other autocomplete functions
  const getCriterionSuggestion = async (libraryIdentifier, ignoredLabels, query) => {
    return get(
      `${process.env.REACT_APP_API_URI}v1/person/criterion/suggestion/${encodeURIComponent(query)}`,
      getAccessToken,
      addToast,
      null,
      null,
      history
    )
  }

  const getPerson = async (identifier, handleSuccess) => {
    return get(
      `${process.env.REACT_APP_API_URI}v1/person/${identifier}`,
      getAccessToken,
      addToast,
      null,
      handleSuccess,
      history
    )
  }

  const getPersons = async (email, handleSuccess) => {
    const params = new URLSearchParams()
    if (email) {
      params.set('email', email)
    }
    return get(
      `${process.env.REACT_APP_API_URI}v1/person/?${params.toString()}`,
      getAccessToken,
      addToast,
      null,
      handleSuccess,
      history
    )
  }

  const getTeamMemberships = async (personIdentifier, handleSuccess) => {
    return get(
      `${process.env.REACT_APP_API_URI}v1/person/${personIdentifier}/team-member`,
      getAccessToken,
      addToast,
      null,
      handleSuccess,
      history
    )
  }

  const getWorksFor = async (personIdentifier, handleSuccess) => {
    return get(
      `${process.env.REACT_APP_API_URI}v1/person/${personIdentifier}/works-for`,
      getAccessToken,
      addToast,
      null,
      handleSuccess,
      history
    )
  }

  const confirmWorksFor = async (
    personIdentifier,
    invitationToken,
    setInProgress,
    handleSuccess
  ) => {
    return save(
      `${process.env.REACT_APP_API_URI}v1/person/${personIdentifier}/works-for/confirm?invitationToken=${invitationToken}`,
      null,
      getAccessToken,
      addToast,
      setInProgress,
      handleSuccess,
      history
    )
  }

  const confirmTeamMember = async (
    personIdentifier,
    invitationToken,
    setInProgress,
    handleSuccess
  ) => {
    return save(
      `${process.env.REACT_APP_API_URI}v1/person/${personIdentifier}/team-member/confirm?invitationToken=${invitationToken}`,
      null,
      getAccessToken,
      addToast,
      setInProgress,
      handleSuccess,
      history
    )
  }
  const saveDeviceId = async (data) => {
    return save(
      `${process.env.REACT_APP_API_URI}v1/person/device`,
      data,
      getAccessToken,
      addToast,
      null,
      null,
      history
    )
  }

  const savePerson = async (data, setInProgress, handleSuccess) => {
    return save(
      `${process.env.REACT_APP_API_URI}v1/person/`,
      data,
      getAccessToken,
      addToast,
      setInProgress,
      handleSuccess,
      history
    )
  }

  return {
    getCriterionSuggestion,
    getOpenItemsForPerson,
    getPerson,
    getPersons,
    getTeamMemberships,
    getWorksFor,
    confirmWorksFor,
    confirmTeamMember,
    saveDeviceId,
    savePerson
  }
}

export default usePersonApi
