import React, { useEffect, useState } from 'react'
import useBillingApi from '../../api/useBillingApi'
import BackgroundBalls from '../../components/BackgroundBalls'
import BillingAdminTabs from './BillingAdminTabs'
import InvoiceLineDetail from './InvoiceLineDetail'

export default function BillingOpenInvoiceLines() {
  const { getInvoiceLines } = useBillingApi()

  const [invoiceLines, setInvoiceLines] = useState([])
  const [inProgress, setInProgress] = useState()

  const refresh = () => {
    getInvoiceLines('InPreparation', null, null, setInProgress, setInvoiceLines)
  }

  useEffect(() => {
    refresh()
  }, [])

  const calculateTotalAmount = (input) => {
    return input.reduce(
      (accumulator, invoiceLine) => accumulator + invoiceLine.numberOfUnits * invoiceLine.unitPrice,
      0
    )
  }

  return (
    <div>
      <BackgroundBalls styleClassed="opacity-1" />
      <section className="hero is-hero-bar">
        <div className="columns is-vcentered mb-0">
          <div className="column is-8 hero-left">
            <p className="title">Billing</p>
          </div>
          <div className="column is-1 hero-center">
            <div className="hero-background">
              <div className="ellipse-1">&nbsp;</div>
            </div>
          </div>
          <div className="column is-3 hero-right">&nbsp;</div>
        </div>
      </section>
      <div className="section">
        <BillingAdminTabs />
        {invoiceLines?.length > 0 && (
          <table className="table is-fullwidth is-striped is-hoverable is-bordered">
            <thead>
              <tr>
                <th className="width-15">Customer</th>
                <th>Description</th>
                <th className="width-5 has-text-right">Units</th>
                <th className="width-8 has-text-right">Price</th>
                <th className="width-5 has-text-right">Discount</th>
                <th className="width-8 has-text-right">Total</th>
                <th className="width-8 has-text-right">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {invoiceLines?.map((invoiceLine) => (
                <InvoiceLineDetail
                  key={invoiceLine.identifier}
                  invoiceLineInput={invoiceLine}
                  isEditable={true}
                  inProgress={inProgress}
                  setInProgress={setInProgress}
                  containsDiscountLines={true}
                  saveSuccessHandler={refresh}
                  showCustomer={true}
                />
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={5}>&nbsp;</td>
                <td className="has-text-right has-text-weight-bold	">
                  &euro; {calculateTotalAmount(invoiceLines).toFixed(2)}
                </td>
                <td>&nbsp;</td>
              </tr>
            </tfoot>
          </table>
        )}
        {!invoiceLines?.length > 0 && (
          <div className="notification is-info is-light">
            <p className="subtitle">There are no open invoice lines</p>
            <p>There are no open invoice lines for next month found.</p>
          </div>
        )}
      </div>
    </div>
  )
}
