import React from 'react'
import { Route } from 'react-router-dom'
import useUserService from '../api/useUserService'
import LoginRequiredView from './LoginRequiredView'
import SignUpView from './authentication/SignUpView'
import MfaView from './authentication/MfaView'
import { numberOfMinutesSinceLogin } from '../utils/utils'

function PrivateRoute({ component: Component, children, path, location, ...rest }) {
  const { user, logout, isAuthenticated, isLoading, isUserVerifiedOrTrusted, isWithMFAOrTrusted } =
    useUserService()

  const render = (props) => {
    if (!isLoading && !isAuthenticated) {
      return <LoginRequiredView />
    }
    if (isAuthenticated === true) {
      if (!isUserVerifiedOrTrusted()) {
        return <SignUpView />
      } else if (!isWithMFAOrTrusted() && location.pathname !== '/configure-mfa') {
        return <MfaView />
      } else if (
        numberOfMinutesSinceLogin(user) > process.env.REACT_APP_MAXIMUM_LOGIN_TIME_IN_MINUTES
      ) {
        logout()
      } else {
        return <Component {...props} />
      }
    }
    return null
  }
  if (Component) {
    return <Route path={path} render={render} {...rest} />
  } else {
    return (
      <Route path={path} {...rest}>
        {children}
      </Route>
    )
  }
}

export default PrivateRoute
