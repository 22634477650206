import React from 'react'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import Icon from '../Icon'

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/require-default-props */

export default function DatePickerNav({
  visibleDate,
  startDate,
  endDate,
  isRange,
  onJump,
  onNext,
  onPrev
}) {
  return (
    <>
      <div className="calendar-nav">
        <div className="calendar-nav-prev-month" onClick={onPrev}>
          <button type="button" className="button is-success">
            <Icon icon="fa:chevron-left" />
          </button>
        </div>
        <div className="calendar-month">{format(visibleDate, 'MMMM yyyy')}</div>
        <div className="calendar-nav-next-month" onClick={onNext}>
          <button type="button" className="button is-success">
            <Icon icon="fa:chevron-right" />
          </button>
        </div>
      </div>
      {isRange && (
        <div className="calendar-nav calendar-nav-range">
          <a className="button is-success" onClick={() => onJump(startDate)}>
            {format(startDate, 'yyyy-MM-dd')}
          </a>
          <Icon icon="fa-solid:long-arrow-alt-right" />
          <a className="button is-success" onClick={() => onJump(endDate)}>
            {format(endDate, 'yyyy-MM-dd')}
          </a>
        </div>
      )}
    </>
  )
}

DatePickerNav.propTypes = {
  endDate: PropTypes.instanceOf(Date),
  isRange: PropTypes.bool,
  onJump: PropTypes.func,
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date),
  visibleDate: PropTypes.instanceOf(Date)
}
