import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Icon from './Icon'

export default function LoginWithUsernamePasswordButton() {
  const location = useLocation();
  const history = useHistory()

  const navigateToSignIn = () => {
    history.replace(`/sign-in?redirectTo=${location.pathname}`)
  }

  return (
    <button type="button" onClick={() => navigateToSignIn()} className="button is-rounded">
      <Icon icon="ic:outline-account-circle" />
      <span>Sign in with username</span>
    </button>
  )
}
