import React from 'react'
import { useHistory } from 'react-router-dom'
import Icon from '../../components/Icon'
import useQuery from '../../utils/useQuery'
import useUserService from '../../api/useUserService'

export default function BillingInvoicesStateTabs() {
  const history = useHistory()
  const query = useQuery()
  const { userHasRole } = useUserService()
  const updateState = (event, state) => {
    query.set('state', state)
    if (event.metaKey || event.ctrlKey || event.type === 'auxclick') {
      window.open(
        `${window.location.href.split('?')[0]}?${query.toString()}`,
        '_blank',
        'noopener,noreferrer'
      )
    } else {
      history.push({ search: query.toString() })
    }
  }

  return (
    <div className="tabs is-small is-table-tabs mb-0">
      <ul>
        {userHasRole('smartHAZOP') && (
          <li
            className={`${query.get('state') === 'InPreparation' ? 'is-active' : ''}`}
            onClick={(event) => updateState(event, 'InPreparation')}
            onAuxClick={(event) => updateState(event, 'InPreparation')}
          >
            <a>
              <Icon icon="material-symbols:line-start-diamond-outline" />
              <span className="is-hidden-mobile">InPreparation</span>
            </a>
          </li>
        )}
        <li
          className={`${(query.get('state') || 'NotPaid') === 'NotPaid' ? 'is-active' : ''}`}
          onClick={(event) => updateState(event, 'NotPaid')}
          onAuxClick={(event) => updateState(event, 'NotPaid')}
        >
          <a>
            <Icon icon="ic:outline-pending-actions" />
            <span className="is-hidden-mobile">Not paid</span>
          </a>
        </li>
        <li
          className={`${query.get('state') === 'Paid' ? 'is-active' : ''}`}
          onClick={(event) => updateState(event, 'Paid')}
          onAuxClick={(event) => updateState(event, 'Paid')}
        >
          <a>
            <Icon icon="ic:outline-attach-money" />
            <span className="is-hidden-mobile">Paid</span>
          </a>
        </li>
      </ul>
    </div>
  )
}
