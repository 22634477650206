import React, { useState } from 'react'

export default function InputPassword({
  value,
  onChange,
  className,
  size,
  placeholder,
  name,
  reference,
  inputMode,
  onKeyUp
}) {
  const [isValid, setValid] = useState(true)
  const pattern = '.*'

  const validateAndChange = (e) => {
    if (pattern && e.target.value.match(pattern) == null) {
      setValid(false)
    } else if (pattern && !isValid) {
      setValid(true)
    }
    onChange(e)
  }

  return (
    <input
      ref={(el) => {
        if (reference) {
          // eslint-disable-next-line no-param-reassign
          reference.current = el
        }
      }}
      type="password"
      inputMode={inputMode}
      autoCapitalize="off"
      autoComplete="off"
      className={`${className} ${isValid || 'is-danger'}`}
      value={value}
      size={size}
      name={name}
      onChange={(e) => validateAndChange(e)}
      placeholder={placeholder}
      pattern={pattern}
      onClick={(event) => {
        event.stopPropagation()
      }}
      onKeyUp={onKeyUp}
    />
  )
}
