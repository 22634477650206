import React from 'react'
import { UserAuth } from '../contexts/AuthContext'
import { numberOfMinutesSinceLogin } from '../utils/utils'
import Icon from './Icon'

export default function ShowLoginExpirePreventorButton({ setForceShowLoginRefresh }) {
  const { user } = UserAuth()

  return (
    user &&
    numberOfMinutesSinceLogin(user) >
      process.env.REACT_APP_TIME_TO_SHOW_LOGIN_PREVENTOR_IN_MINUTES && (
      <span className="navbar-item is-desktop-icon-only has-text-primary-dark-blue pr-0">
        <button
          type="button"
          onClick={() => setForceShowLoginRefresh(true)}
          title="Refresh authentication"
          className="button is-ghost"
        >
          <Icon icon="ic:round-update" extraOuterClasses="has-text-primary-dark-blue" />
          <span>Refresh authentication</span>
        </button>
      </span>
    )
  )
}
