import React, { Component } from 'react'
import { delay } from 'lodash'
import PropTypes from 'prop-types'
import Icon from '../Icon'

/* eslint-disable react/require-default-props */

/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */

export default class Dialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isSubmitDisabled: this.props.custom && this.props.custom.mustEqualToSubmit !== undefined,
      loading: false,
      // eslint-disable-next-line no-nested-ternary
      selected: !this.props.custom
        ? !this.props.choices
          ? true
          : this.props.choices[0].value
        : this.props.custom.selected,
      resolved: undefined
    }
  }

  componentDidMount() {
    if (!this.props.isForced) {
      window.addEventListener('keyup', this.onKeyUp)
    }
  }

  componentWillUnmount() {
    if (this.props.cancel) {
      window.removeEventListener('keyup', this.onKeyUp)
    }
  }

  onKeyUp = (event) => {
    if (event.code === 'Escape' && !this.props.isForced) this.reject()
    else if (event.key === 'Enter') this.onClickSubmit()
    return false
  }

  onChoiceClick = (selected) => {
    this.setState({ selected })
  }

  onCustomUpdate = (selected) => {
    const isSubmitDisabled =
      this.props.custom.mustEqualToSubmit && this.props.custom.mustEqualToSubmit !== selected
    this.setState({ selected, isSubmitDisabled })
    if (!this.props.submit && !isSubmitDisabled) this.resolve(selected)
  }

  onCustomRegister = (customSubmit) => {
    this.customSubmit = customSubmit
  }

  onCustomLoading = (loading) => this.setState({ loading })

  onCustomSubmitDisabled = (isSubmitDisabled) => this.setState({ isSubmitDisabled })

  onClickSubmit = () => {
    if (this.state.isSubmitDisabled) return
    if (this.customSubmit) {
      this.customSubmit()
      return
    }
    this.resolve(this.state.selected)
  }

  resolve = (value) => {
    this.setState({ resolved: 'has-submitted' }, () => {
      delay(() => {
        this.props.resolve(value)
      }, 150)
    })
  }

  reject = () => {
    if (!this.state.loading && !!this.props.reject) {
      this.setState({ resolved: 'has-canceled' }, () => {
        delay(() => {
          this.props.reject()
        }, 150)
      })
    }
  }

  render() {
    const getDisabled = (bool) => (bool ? { disabled: 'disabled' } : {})
    return (
      <div
        className={`modal is-active is-${this.props.type} ${this.props.styles.dialog || ''} ${
          this.state.resolved || ''
        }`}
      >
        <div
          className="modal-background"
          onClick={!this.props.isForced && !!this.props.reject && this.reject}
        />
        <div className="modal-card">
          <header className="modal-card-head">
            {this.props.icon && <Icon icon={`${this.props.icon}`} />}
            <p className="modal-card-title">{this.props.title}</p>
            {!this.props.isForced && (
              <button
                type="button"
                className="delete"
                aria-label="close"
                onClick={!!this.props.reject && this.reject}
              />
            )}
          </header>
          <section className={`modal-card-body ${this.props.styles.body || ''}`}>
            {this.props.message && (
              <div
                className="modal-message"
                dangerouslySetInnerHTML={{
                  __html: this.props.message
                }}
              />
            )}
            {this.props.choices && (
              <div className="control">
                {this.props.choices.map((item) => (
                  <label className="radio" key={item.label}>
                    {item.value === this.state.selected ? (
                      <input type="radio" checked />
                    ) : (
                      <input
                        type="radio"
                        onClick={() => {
                          this.onChoiceClick(item.value)
                        }}
                      />
                    )}
                    {item.label}
                  </label>
                ))}
              </div>
            )}
            {this.props.custom && (
              <this.props.custom.View
                onLoading={this.onCustomLoading}
                onRegister={this.onCustomRegister}
                onSubmitDisabled={this.onCustomSubmitDisabled}
                onUpdate={this.onCustomUpdate}
                reject={this.reject}
                resolve={this.resolve}
                {...this.props.custom.props}
              />
            )}
          </section>
          {(this.props.submit || this.props.cancel) && (
            <footer className="modal-card-foot">
              {this.props.submit && (
                <button
                  type="button"
                  className={`button is-${this.props.type} ${
                    this.state.loading ? 'is-loading' : ''
                  }`}
                  aria-label={this.props.submit}
                  onClick={this.onClickSubmit}
                  {...getDisabled(this.state.isSubmitDisabled)}
                >
                  {this.props.submit}
                </button>
              )}
              {this.props.cancel && !this.props.isForced && (
                <button
                  type="button"
                  className="button is-cancel"
                  aria-label="cancel"
                  onClick={this.reject}
                >
                  {this.props.cancel}
                </button>
              )}
            </footer>
          )}
        </div>
      </div>
    )
  }
}

Dialog.propTypes = {
  cancel: PropTypes.string,
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),
  custom: PropTypes.shape({
    mustEqualToSubmit: PropTypes.any,
    props: PropTypes.object,
    View: PropTypes.func.isRequired,
    selected: PropTypes.any
  }),
  icon: PropTypes.string,
  isForced: PropTypes.bool,
  message: PropTypes.string,
  reject: PropTypes.func,
  resolve: PropTypes.func,
  styles: PropTypes.shape({
    body: PropTypes.string,
    dialog: PropTypes.string
  }),
  submit: PropTypes.string,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['primary', 'accent', 'info', 'warning', 'danger', 'success'])
}

Dialog.defaultProps = {
  type: 'primary',
  styles: {}
}
