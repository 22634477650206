import React from 'react'
import ReactDOM from 'react-dom'
import Dialog from './Dialog'

export default (props) => {
  let container = document.getElementById('dialog-container')
  if (!container) {
    container = document.createElement('div')
    container.id = 'dialog-container'
    document.body.appendChild(container)
  }
  const div = container.appendChild(document.createElement('div'))
  const root = document.getElementById('root')

  if (process.env.NODE_ENV !== 'test') {
    root.classList.add('modal-blur')
  }

  const cleanup = () => {
    if (process.env.NODE_ENV !== 'test') {
      try {
        ReactDOM.unmountComponentAtNode(div)
        container.removeChild(div)
      } catch (e) {
        // it's ok
      }
      root.classList.remove('modal-blur')
    }
  }

  return new Promise((resolve, reject) => {
    if (props.subtree) {
      ReactDOM.unstable_renderSubtreeIntoContainer(
        props.subtree,
        <Dialog {...props} resolve={resolve} reject={reject} />,
        div
      )
    } else {
      ReactDOM.render(<Dialog {...props} resolve={resolve} reject={reject} />, div)
    }
  })
    .then((result) => {
      cleanup()
      return result
    })
    .catch((error) => {
      if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
        /* eslint-disable no-console */
        console.warn(error)
      }
      cleanup()
      return undefined
    })
}
