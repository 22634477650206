import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import useQuery from '../../utils/useQuery'
import ResetPasswordView from './ResetPasswordView'
import { UserAuth } from '../../contexts/AuthContext'

export default function AuthenticationActionView() {
  const history = useHistory()
  const query = useQuery()
  const { handleActionCode } = UserAuth()
  const [errorMessage, setErrorMessage] = useState()

  useEffect(async () => {
    if (query.has('mode') && query.has('oobCode')) {
      if (
        query.get('mode') === 'verifyEmail' ||
        query.get('mode') === 'revertSecondFactorAddition'
      ) {
        try {
          await handleActionCode(query.get('oobCode'))
          if (query.has('continueUrl')) {
            window.location.href = query.get('continueUrl')
          } else {
            history.push('/')
          }
        } catch (e) {
          switch (e.code) {
            case 'auth/invalid-action-code':
              setErrorMessage('The url used is not valid. Try to request a new url.')
              break
            default:
              setErrorMessage(e.message)
              break
          }
        }
      }
    }
  }, [])

  if (!query.has('mode')) {
    return 'This page expects a mode query parameter'
  }
  if (query.get('mode') === 'verifyEmail') {
    return <div>{errorMessage && <p className="has-text-danger">{errorMessage}</p>}</div>
  }
  if (query.get('mode') === 'revertSecondFactorAddition') {
    return <div>{errorMessage && <p className="has-text-danger">{errorMessage}</p>}</div>
  }
  if (query.get('mode') === 'resetPassword') {
    return <ResetPasswordView />
  }
}
