import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { UserAuth } from '../../contexts/AuthContext'
import RefreshAuthenticationButton from '../../components/RefreshAuthenticationButton'
import { numberOfMinutesSinceLogin } from '../../utils/utils'
import SignInComponent from './SignInComponent'
import Icon from '../../components/Icon'

export default function LoginExpirePreventor({ forceShowLoginRefresh, setForceShowLoginRefresh }) {
  const location = useLocation()

  const { user, logout, counter } = UserAuth()
  const [showRefresh, setShowRefresh] = useState(false)
  const [snoozeUntil, setSnoozeUntil] = useState()
  const snoozeTime = 1000 * 60 * 60

  const snooze = (event) => {
    if (!event || event.keyCode === 27) {
      setSnoozeUntil(Date.now() + snoozeTime)
      setForceShowLoginRefresh(false)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', snooze, false)

    return () => {
      document.removeEventListener('keydown', snooze, false)
    }
  }, [snooze])

  useEffect(() => {
    setForceShowLoginRefresh(false)
    if (user) {
      if (numberOfMinutesSinceLogin(user) > process.env.REACT_APP_MAXIMUM_LOGIN_TIME_IN_MINUTES) {
        logout()
      } else if (
        numberOfMinutesSinceLogin(user) >
        process.env.REACT_APP_TIME_TO_SHOW_LOGIN_PREVENTOR_IN_MINUTES
      ) {
        setShowRefresh(true)
      } else {
        setShowRefresh(false)
      }
    }
  }, [location, user, counter])

  if (
    (user && showRefresh && (!snoozeUntil || Date.now() > snoozeUntil)) ||
    forceShowLoginRefresh
  ) {
    return (
      <div className="modal is-active">
        <div className="modal-background" onClick={() => snooze()} />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Keep your data safe</p>
            <button type="button" className="button is-ghost" onClick={() => snooze()}>
              Snooze
            </button>
          </header>
          <section className="modal-card-body">
            <div className="columns is-flex is-vcentered">
              <div className="column is-8-desktop is-12-mobile is-12-tablet">
                <p className="mb-2">
                  It has been a couple of hours since your last login. To keep your data protected
                  you will soon be signed-out. Please Re-authenticate if you want to continue.
                </p>
                <p className="mb-5">
                  If this moment is not convenient you can snooze for a while. But not too long
                  because you know what they say; <i>you snooze you lose</i>. Use the{' '}
                  <Icon icon="ic:round-update" /> button in the navigation bar to open this window
                  again.
                </p>
                {user.providerData?.length > 0 &&
                  user.providerData[0].providerId === 'microsoft.com' && (
                    <RefreshAuthenticationButton />
                  )}
                {user.providerData?.length > 0 &&
                  user.providerData[0].providerId !== 'microsoft.com' && (
                    <SignInComponent refreshLogin={true} />
                  )}
              </div>
              <div className="column">&nbsp;</div>
            </div>
          </section>
          <footer className="modal-card-foot">&nbsp;</footer>
        </div>
      </div>
    )
  } else {
    return <>&nbsp;</>
  }
}
