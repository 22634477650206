import React from 'react'
import { useHistory } from 'react-router-dom'
import { UserAuth } from '../contexts/AuthContext'
import useToastContext from '../hooks/useToastContext'
import Icon from './Icon'

export default function LoginWithMicrosoftButton({ signup = false }) {
  const history = useHistory()
  const addToast = useToastContext()
  const { signInWithMicrosoft } = UserAuth()

  const login = async () => {
    try {
      await signInWithMicrosoft()
    } catch (e) {
      switch (e.code) {
        case 'auth/account-exists-with-different-credential':
          addToast({
            level: 'is-danger',
            message:
              'This user is previously used with a different login method. Please try to login using username and password or other methods if available.'
          })
          history.push('/sign-in?redirectTo=/')
          break
        case 'auth/invalid-credential':
          if (e.message?.includes('consent_required')) {
            addToast({
              level: 'is-danger',
              message:
                'Your consent is required before you can use your Microsoft account with smartHAZOP®'
            })
          } else {
            addToast({
              level: 'is-danger',
              message: 'Invalid credentials'
            })
          }
          break
        default:
          addToast({ level: 'is-danger', message: e.message })
          console.log(e.code)
          break
      }
    }
  }

  return (
    <button type="button" onClick={() => login()} className="button is-rounded">
      <Icon icon="logos:microsoft-icon" extraOuterClasses="p-1" />
      <span>{`${signup ? 'Sign up' : 'Sign in'} with Microsoft`}</span>
    </button>
  )
}
