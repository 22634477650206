import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import BackgroundBalls from '../../components/BackgroundBalls'
import Input from '../../components/Input'
import { UserAuth } from '../../contexts/AuthContext'
import Icon from '../../components/Icon'
import LoginWithMicrosoftButton from '../../components/LoginWithMicrosoftButton'

export default function ForgotPasswordView() {
  const history = useHistory()

  const { isLoading, passwordReset, user } = UserAuth()
  const [error, setError] = useState('')
  const [successMessage, setSuccessMessage] = useState()
  const [showLoginWithMicrosoftButton, setShowLoginWithMicrosoftButton] = useState(false)
  const [email, setEmail] = useState('')

  const handlePasswordReset = async () => {
    setError('')
    try {
      await passwordReset(email)
      setSuccessMessage('An email has been sent with instructions to reset your password')
    } catch (e) {
      switch (e.code) {
        case 'auth/user-not-found':
          setError(
            'This email address is unknown, please create an account or Sign in with Microsoft'
          )
          setShowLoginWithMicrosoftButton(true)
          break
        case 'auth/invalid-email':
          setError('Email address is not valid')
          break
        default:
          setError(e.message)
          break
      }
    }
  }

  return (
    <>
      <BackgroundBalls />
      {!isLoading && (
        <div>
          <div className="columns is-flex is-vcentered is-fullheight">
            <div className="column">&nbsp;</div>
            <div className="column is-5-desktop is-11-mobile is-8-tablet">
              <div className="card">
                <div className="card-content p-5">
                  <div className="content">
                    <p className="title">Reset yourSmartHAZOP® password</p>
                    {!user && !isLoading && (
                      <>
                        <p>
                          Lost your password? Please enter your email address. You will receive a
                          link to create a new password via email.
                        </p>
                        {error && <p className="has-text-warning">{error}</p>}
                        {showLoginWithMicrosoftButton && <LoginWithMicrosoftButton />}
                        {successMessage && <p className="has-text-success">{successMessage}</p>}
                        {!successMessage && (
                          <>
                            <div className="field">
                              <label className="label">Email</label>
                              <div className="control">
                                <Input
                                  className="input"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                />
                              </div>
                            </div>

                            <div className="buttons is-right mt-4">
                              <button
                                type="button"
                                className="button is-ghost"
                                onClick={() => history.push('/')}
                              >
                                <Icon icon="ic:baseline-arrow-back" />
                                <span>Back</span>
                              </button>
                              <button
                                type="button"
                                className="button is-rounded is-primary"
                                onClick={handlePasswordReset}
                              >
                                <span>Reset password</span>
                              </button>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="column">&nbsp;</div>
          </div>
        </div>
      )}
    </>
  )
}
