import React from 'react'
import { UserAuth } from '../contexts/AuthContext'
import Icon from './Icon'

function RefreshAuthenticationButton() {
  const { user, reauthenticateWithMicrosoft } = UserAuth()

  return (
    user && (
      <button
        type="button"
        onClick={() => reauthenticateWithMicrosoft()}
        title="Refresh authentication"
        className="button is-rounded is-primary"
      >
        <Icon icon="ic:round-update" />
        <span>Re-authenticate</span>
      </button>
    )
  )
}

export default RefreshAuthenticationButton
