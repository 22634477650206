import React, { useState } from 'react'
import { format, parse, startOfMonth, startOfToday } from 'date-fns'
import useUserService from '../../api/useUserService'
import DatePicker from '../../components/DatePicker/DatePicker'
import Icon from '../../components/Icon'
import useToastContext from '../../hooks/useToastContext'
import useBillingApi from '../../api/useBillingApi'
import BackgroundBalls from '../../components/BackgroundBalls'
import BillingAdminTabs from './BillingAdminTabs'

export default function TriggerGenerateInvoices() {
  const { userHasRole } = useUserService()
  const { generateInvoices } = useBillingApi()
  const addToast = useToastContext()

  const [invoiceDate, setInvoiceDate] = useState(format(startOfMonth(startOfToday()), 'yyyy-MM-dd'))
  const [inProgress, setInProgress] = useState()

  const isUserAllowedToEdit = () => {
    return userHasRole('smartHAZOP')
  }

  function handleSaveSuccess() {
    addToast({ level: 'is-success', message: 'Invoices generated' })
  }

  const triggerGenerateInvoices = () => {
    generateInvoices(invoiceDate, setInProgress, handleSaveSuccess)
  }

  const changeDate = (newValue) => {
    setInvoiceDate(format(newValue.startDate, 'yyyy-MM-dd'))
  }

  return (
    <div>
      <BackgroundBalls styleClassed="opacity-1" />
      <section className="hero is-hero-bar">
        <div className="columns is-vcentered mb-0">
          <div className="column is-8 hero-left">
            <p className="title">Billing</p>
          </div>
          <div className="column is-1 hero-center">
            <div className="hero-background">
              <div className="ellipse-1">&nbsp;</div>
            </div>
          </div>
          <div className="column is-3 hero-right">&nbsp;</div>
        </div>
      </section>
      <div className="section">
        <BillingAdminTabs />
        <div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Invoice date</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <DatePicker
                    name="due"
                    startDate={parse(invoiceDate, 'yyyy-MM-dd', new Date())}
                    onChange={changeDate}
                    showHelp={false}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="buttons is-right">
            {isUserAllowedToEdit() && (
              <button
                type="button"
                className="button is-rounded is-primary"
                onClick={triggerGenerateInvoices}
                disabled={inProgress}
              >
                <Icon icon="ic:outline-save" />
                <span>Generate invoices</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
