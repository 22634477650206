import React, { lazy, Suspense, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import Nav from './components/Nav'
import Footer from './components/Footer'
import { ToastContextProvider } from './contexts/ToastContext'
import PrivateRoute from './views/PrivateRoute'
import SignUpView from './views/authentication/SignUpView'
import SignInView from './views/authentication/SignInView'
import ForgotPasswordView from './views/authentication/ForgotPasswordView'
import MfaView from './views/authentication/MfaView'
import AuthenticationActionView from './views/authentication/AuthenticationActionView'
import LoginExpirePreventor from './views/authentication/LoginExpirePreventor'
import LibraryOverview from './views/library/LibraryOverview'
import BillingNavigation from './views/billing/BillingNavigation'

const ActionOverview = lazy(() => import('./views/ActionOverview'))
const CustomerNavigation = lazy(() => import('./views/customer/CustomerNavigation'))
const HazopStudyNavigation = lazy(() => import('./views/HazopStudyNavigation'))
const PersonNavigation = lazy(() => import('./views/PersonNavigation'))
const ErrorView = lazy(() => import('./views/ErrorView'))
const HelpNavigation = lazy(() => import('./views/help/HelpNavigation'))
const DataSourceNavigation = lazy(() => import('./views/data-source/DataSourceNavigation'))
const LibraryNavigation = lazy(() => import('./views/library/LibraryNavigation'))
const HomeView = lazy(() => import('./views/HomeView'))
const ConversationOverview = lazy(() => import('./views/ConversationOverview'))

const renderLoader = () => <p>&nbsp;</p>

function App() {
  const [forceShowLoginRefresh, setForceShowLoginRefresh] = useState(false)

  return (
    <Suspense fallback={renderLoader()}>
      <ToastContextProvider>
        <div className="app">
          <Nav setForceShowLoginRefresh={setForceShowLoginRefresh} />
          <Switch>
            <Route exact path="/" component={HomeView} />
            <Route exact path="/sign-up" component={SignUpView} />
            <Route exact path="/sign-in" component={SignInView} />
            <Route exact path="/forgot-password" component={ForgotPasswordView} />
            <Route exact path="/auth/action" component={AuthenticationActionView} />
            <PrivateRoute exact path="/configure-mfa">
              <MfaView setForceShowLoginRefresh={setForceShowLoginRefresh} />
            </PrivateRoute>
            <Route exact path="/error" component={ErrorView} />
            <Route path="/help" component={HelpNavigation} />
            <PrivateRoute path="/hazopstudy/:projectNumber" component={HazopStudyNavigation} />
            <PrivateRoute exact path="/conversation/" component={ConversationOverview} />
            <PrivateRoute exact path="/action/" component={ActionOverview} />
            <PrivateRoute path="/person" component={PersonNavigation} />
            <PrivateRoute path="/customer" component={CustomerNavigation} />
            <PrivateRoute path="/billing" component={BillingNavigation} />
            <PrivateRoute exact path="/library" component={LibraryOverview} />
            <PrivateRoute path="/library/:libraryIdentifier" component={LibraryNavigation} />
            <PrivateRoute path="/data-source" component={DataSourceNavigation} />
          </Switch>
          <LoginExpirePreventor
            setForceShowLoginRefresh={setForceShowLoginRefresh}
            forceShowLoginRefresh={forceShowLoginRefresh}
          />
          <input id="recaptcha" type="submit" value="Send Phone" style={{ visibility: 'hidden' }} />
        </div>
        <Footer />
      </ToastContextProvider>
    </Suspense>
  )
}

export default App
