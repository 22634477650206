import { addDays, format, isAfter, isBefore, isEqual, isValid, parse, startOfDay } from 'date-fns'
import React, { Component } from 'react'
import classes from 'classnames'
import PropTypes from 'prop-types'
import DatePickerDialog from './DatePickerDialog'
import Dialog from '../Dialog'
import Input from '../Input'
import Icon from '../Icon'

/* eslint-disable react/require-default-props */

/* eslint-disable react/destructuring-assignment */

export class DatePicker extends Component {
  constructor(props) {
    super(props)
    const startDate =
      !props.minDate ||
      isAfter(props.startDate, props.minDate) ||
      isEqual(props.startDate, props.minDate)
        ? props.startDate
        : undefined
    // eslint-disable-next-line no-nested-ternary
    const endDate = props.isRange
      ? !props.maxDate || isBefore(props.endDate, props.maxDate)
        ? props.endDate
        : props.maxDate
      : undefined
    this.state = {
      startDate: startDate ? startOfDay(startDate) : undefined,
      endDate: endDate ? startOfDay(endDate) : undefined,
      formattedStartDate: startDate ? format(startDate, props.dateFormat) : '',
      formattedEndDate: endDate ? format(endDate, props.dateFormat) : ''
    }
  }

  onClick = () =>
    Dialog({
      title: '',
      styles: {
        dialog: classes('date-picker-dialog', {
          'date-picker-dialog-range': this.props.isRange
        })
      },
      cancel: this.props.isRange ? 'Cancel' : undefined,
      submit: this.props.isRange ? 'Save' : undefined,
      custom: {
        View: DatePickerDialog,
        props: {
          maxDate: this.props.maxDate,
          minDate: this.props.minDate,
          isRange: this.props.isRange,
          startDate: this.state.startDate || this.props.minDate,
          endDate: this.state.endDate
        }
      }
    }).then(this.onChange)

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  onChange = (event) => {
    if (event?.startDate) {
      this.setState(
        (previousState) => ({
          ...previousState,
          startDate: event.startDate,
          endDate: event.endDate,
          errorStartDate: false,
          errorEndDate: false,
          formattedStartDate: format(event.startDate, this.props.dateFormat),
          formattedEndDate: event.endDate ? format(event.endDate, this.props.dateFormat) : ''
        }),
        () =>
          this.props.onChange({
            name: this.props.name,
            startDate: this.state.startDate,
            endDate: this.state.endDate
          })
      )
    } else if (event?.target) {
      const parsedDate = parse(event.target.value, 'yyyy-MM-dd', new Date())
      if (isValid(parsedDate)) {
        this.setState(
          (previousState) => ({
            ...previousState,
            [`formatted${this.capitalizeFirstLetter(event.target.name)}`]: event.target.value,
            [event.target.name]: parsedDate || previousState[event.target.name],
            [`error${this.capitalizeFirstLetter(event.target.name)}`]: false
          }),
          () =>
            this.props.onChange({
              name: this.props.name,
              startDate: this.state.startDate,
              endDate: this.state.endDate
            })
        )
      } else if (event.target.value === '' && this.props.isEmptyAllowed) {
        this.setState(
          (previousState) => ({
            ...previousState,
            [`formatted${this.capitalizeFirstLetter(event.target.name)}`]: event.target.value,
            [event.target.name]: undefined,
            [`error${this.capitalizeFirstLetter(event.target.name)}`]: false
          }),
          () =>
            this.props.onChange({
              name: this.props.name,
              startDate: this.state.startDate,
              endDate: this.state.endDate
            })
        )
      } else {
        this.setState((previousState) => ({
          ...previousState,
          [`formatted${this.capitalizeFirstLetter(event.target.name)}`]: event.target.value,
          [`error${this.capitalizeFirstLetter(event.target.name)}`]: true
        }))
      }
    }
  }

  render() {
    const getDisabled = (bool) => (bool ? { disabled: 'disabled' } : {})
    const { className, dateFormat, disabled, isRange, showHelp = true } = this.props
    const { name, startDate, endDate, formattedStartDate, formattedEndDate, errorStartDate } =
      this.state
    const click = !disabled ? this.onClick : undefined
    return (
      <div
        className={classes('field date-picker', {
          'has-addons': !disabled,
          [className]: !!className
        })}
      >
        <div className="control">
          <Input
            className={`input ${errorStartDate && 'is-danger'} ${disabled && 'is-static'}`}
            readOnly={disabled}
            value={formattedStartDate}
            name="startDate"
            onChange={(e) => this.onChange(e)}
            placeholder={dateFormat}
          />
          {!disabled && showHelp && <p className="help has-text-grey pl-3">yyyy-mm-dd</p>}
        </div>
        {!disabled && (
          <div className="control">
            <a className="button" onClick={click}>
              <Icon icon="fa-regular:calendar-alt" extraOuterClasses="p-1" />
            </a>
          </div>
        )}
        {/* <a className="button date-picker-button" onClick={click} {...getDisabled(disabled)}> */}
        {/*  <span className="icon"> */}
        {/*    <i className="far fa-calendar-alt"/> */}

        {/*  </span> */}
        {/*  <span>{formattedStartDate}</span> */}
        {/*  {isRange && ( */}
        {/*    <Fragment> */}
        {/*      <span className="icon"> */}
        {/*        <i className="fas fa-long-arrow-alt-right"/> */}
        {/*      </span> */}
        {/*      <span>{formattedEndDate}</span> */}
        {/*    </Fragment> */}
        {/*  )} */}
        {/* </a> */}
      </div>
    )
  }
}

DatePicker.propTypes = {
  className: PropTypes.string,
  dateFormat: PropTypes.string,
  disabled: PropTypes.bool,
  endDate: PropTypes.instanceOf(Date),
  isRange: PropTypes.bool,
  isEmptyAllowed: PropTypes.bool,
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date)
}

DatePicker.defaultProps = {
  dateFormat: 'yyyy-MM-dd',
  startDate: undefined,
  isEmptyAllowed: false,
  endDate: undefined
}

export default DatePicker
