import React from 'react'
import ReactDOM from 'react-dom'
import './scss/main.scss'
import { Router } from 'react-router-dom'
import App from './App'
import history from './utils/history'
import { AuthContextProvider } from './contexts/AuthContext'

ReactDOM.render(
  <React.StrictMode>
    <AuthContextProvider>
      <Router history={history}>
        <App />
      </Router>
    </AuthContextProvider>
  </React.StrictMode>,

  document.getElementById('root')
)
