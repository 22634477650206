import React from 'react'
import { useHistory } from 'react-router-dom'

export default function SignUpLink() {
  const history = useHistory()
  return (
    <button type="button" className="button is-ghost" onClick={() => history.push(`/sign-up`)}>
      <span>Sign up</span>
    </button>
  )
}
