import React from 'react'

export default function EmptyContent({ text }) {
  return (
    <div className="columns">
      <div className="column">&nbsp;</div>
      <div className="column is-one-fifth-desktop is-one-third-tablet">
        <figure className="image is-1by1">
          <img src="/undraw_empty.svg" alt="not found" />
        </figure>
        <p>{text}</p>
      </div>
      <div className="column">&nbsp;</div>
    </div>
  )
}
