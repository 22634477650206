import React from 'react'
import { useLocation } from 'react-router-dom'

export default function GordiumLink() {
  const location = useLocation()

  return (
    <a href={`https://app.gordium.eu${location.pathname}`} className="navbar-item">
      <p className="subtitle mb-0">Click here to open this screen in Gordium®</p>
    </a>
  )
}
