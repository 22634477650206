import React, { useEffect, useState } from 'react'

export default function Input({
  value,
  className,
  size,
  onChange,
  onKeyDown,
  onKeyUp,
  onKeyPress,
  onBlur,
  placeholder,
  name,
  readOnly,
  pattern,
  updateValueValid,
  reference,
  inputMode
}) {
  const [isValid, setValid] = useState(true)

  const validate = (inputValue) => {
    if (pattern && (inputValue || '').match(pattern) == null) {
      setValid(false)
      if (updateValueValid) {
        updateValueValid(name, false)
      }
    } else if (pattern && !isValid) {
      setValid(true)
      if (updateValueValid) {
        updateValueValid(name, true)
      }
    }
  }

  const validateAndChange = (e) => {
    validate(e.target.value)
    onChange(e)
  }

  useEffect(() => {
    validate(value)
  }, [])

  return (
    <input
      ref={(el) => {
        if (reference) {
          // eslint-disable-next-line no-param-reassign
          reference.current = el
        }
      }}
      type="text"
      inputMode={inputMode}
      autoCapitalize="off"
      autoComplete="off"
      className={`${className} ${isValid ? '' : 'is-danger'}`}
      value={value}
      size={size}
      name={name}
      onChange={(e) => validateAndChange(e)}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onKeyPress={onKeyPress}
      onBlur={onBlur}
      placeholder={placeholder}
      readOnly={readOnly}
      onClick={(event) => {
        if (!readOnly) {
          event.stopPropagation()
        }
      }}
    />
  )
}
