import React from 'react'
import { Route, Switch } from 'react-router-dom'
import BillingOverview from './BillingOverview'
import InvoiceAgreementOverview from './InvoiceAgreementOverview'
import TriggerGenerateInvoices from './TriggerGenerateInvoices'
import BillingOpenInvoiceLines from './BillingOpenInvoiceLines'

export default function BillingNavigation() {
  return (
    <Switch>
      <Route exact path="/billing/generate-invoices">
        <TriggerGenerateInvoices />
      </Route>
      <Route exact path="/billing/open-invoice-line">
        <BillingOpenInvoiceLines />
      </Route>
      <Route exact path="/billing/invoice">
        <BillingOverview />
      </Route>
      <Route exact path="/billing/invoice-agreement">
        <InvoiceAgreementOverview />
      </Route>
    </Switch>
  )
}
