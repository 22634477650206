import React from 'react'
import { isGordium } from '../utils/utils'

export default function Footer() {
  return (
    <footer className="footer">
      <div className="subfooter">
        <div className="content has-text-right">
          <p>
            Copyright ©{new Date().getFullYear()}{' '}
            <strong>{`${isGordium() ? 'Gordium' : 'smartHAZOP'}®`}</strong> All Rights Reserved.
            Application version: {process.env.REACT_APP_VERSION}
          </p>
        </div>
      </div>
    </footer>
  )
}
