import React, { useState } from 'react'
import useBillingApi from '../../api/useBillingApi'
import useToastContext from '../../hooks/useToastContext'
import Input from '../../components/Input'
import Icon from '../../components/Icon'

export default function InvoiceLineDetail({
  invoiceLineInput,
  isEditable,
  inProgress,
  setInProgress,
  containsDiscountLines,
  saveSuccessHandler,
  showCustomer = false
}) {
  const addToast = useToastContext()

  const [invoiceLine, setInvoiceLine] = useState(invoiceLineInput)
  const { saveInvoiceLine } = useBillingApi()

  const updateValue = (event) => {
    setInvoiceLine((previousState) => ({
      ...previousState,
      [event.target.name]: event.target.value
    }))
  }

  const handleSuccess = (data) => {
    addToast({ level: 'is-success', message: 'Invoice agreement is successfully saved.' })
    saveSuccessHandler(data)
  }

  const save = () => {
    saveInvoiceLine(invoiceLine, setInProgress, handleSuccess)
  }
  const remove = () => {
    saveInvoiceLine({ ...invoiceLine, state: 'Deleted' }, setInProgress, handleSuccess)
  }

  return (
    <tr>
      {!isEditable && (
        <>
          <td>{invoiceLine.description}</td>
          <td className="has-text-right">{invoiceLine.numberOfUnits}</td>
          <td className="has-text-right">&euro; {invoiceLine.unitPrice.toFixed(2)}</td>
          {containsDiscountLines && (
            <td className="has-text-right">
              {invoiceLine.unitDiscount && (
                <span>&euro; {invoiceLine.unitDiscount.toFixed(2)}</span>
              )}
            </td>
          )}
          <td className="has-text-right">
            &euro;{' '}
            {(
              (invoiceLine.unitPrice - invoiceLine.unitDiscount) *
              invoiceLine.numberOfUnits
            ).toFixed(2)}
          </td>
        </>
      )}
      {isEditable && (
        <>
          {showCustomer && <td>{invoiceLine.customerName}</td>}
          <td>
            <Input
              className="input"
              name="description"
              value={invoiceLine.description}
              onChange={updateValue}
            />
          </td>
          <td className="has-text-right">
            <Input
              className="input"
              name="numberOfUnits"
              value={invoiceLine.numberOfUnits}
              onChange={updateValue}
            />
          </td>
          <td className="has-text-right">
            <Input
              className="input"
              name="unitPrice"
              value={invoiceLine.unitPrice}
              onChange={updateValue}
            />
          </td>
          <td className="has-text-right">
            <Input
              className="input"
              name="unitDiscount"
              value={invoiceLine.unitDiscount}
              onChange={updateValue}
            />
          </td>
          <td className="has-text-right">
            &euro;{' '}
            {(
              (invoiceLine.unitPrice - invoiceLine.unitDiscount) *
              invoiceLine.numberOfUnits
            ).toFixed(2)}
          </td>
          <td>
            <button
              type="button"
              className="button no-border"
              onClick={() => save()}
              disabled={inProgress}
            >
              <Icon icon="ic:outline-save" />
            </button>
            <button
              type="button"
              className="button no-border has-text-danger"
              onClick={() => remove()}
              disabled={inProgress}
            >
              <Icon icon="ic:outline-close" />
            </button>
          </td>
        </>
      )}
    </tr>
  )
}
