import React from 'react'
import BackgroundBalls from '../../components/BackgroundBalls'
import SignInComponent from './SignInComponent'

export default function SignInView() {
  return (
    <>
      <BackgroundBalls />
      <div>
        <div className="columns is-flex is-vcentered is-fullheight">
          <div className="column">&nbsp;</div>
          <div className="column is-5-desktop is-11-mobile is-8-tablet">
            <p className="title is-2 has-text-centered">Login to SmartHAZOP®</p>
            <div className="card">
              <div className="card-content p-5">
                <div className="content">
                  <SignInComponent />
                </div>
              </div>
            </div>
          </div>
          <div className="column">&nbsp;</div>
        </div>
      </div>
    </>
  )
}
