import { useHistory } from 'react-router-dom'
import useToastContext from '../hooks/useToastContext'
import save, { deleteRequest, get } from '../hooks/authenticated-fetch'
import useUserService from './useUserService'

const useSmartHazopApi = () => {
  const history = useHistory()
  const addToast = useToastContext()
  const { getAccessToken, resourceRoles } = useUserService()

  const getLibraries = async (setInProgress, handleSuccess) => {
    return get(
      `${process.env.REACT_APP_API_URI}v1/smarthazop/library`,
      getAccessToken,
      addToast,
      setInProgress,
      handleSuccess,
      history
    )
  }

  const getLibrary = async (identifier, handleSuccess) => {
    return get(
      `${process.env.REACT_APP_API_URI}v1/smarthazop/library/${identifier}`,
      getAccessToken,
      addToast,
      null,
      handleSuccess,
      history,
      (roles) => {
        resourceRoles.current[identifier] = roles
      }
    )
  }

  const connectComponentToCriterion = async (
    libraryIdentifier,
    criterionIdentifier,
    componentIdentifier,
    handleSuccess
  ) => {
    return save(
      `${process.env.REACT_APP_API_URI}v1/smarthazop/library/${libraryIdentifier}/criterion/${criterionIdentifier}/connect/${componentIdentifier}`,
      null,
      getAccessToken,
      addToast,
      null,
      handleSuccess,
      history
    )
  }

  const disconnectComponentToCriterion = async (
    libraryIdentifier,
    criterionIdentifier,
    componentIdentifier,
    handleSuccess
  ) => {
    return deleteRequest(
      `${process.env.REACT_APP_API_URI}v1/smarthazop/library/${libraryIdentifier}/criterion/${criterionIdentifier}/disconnect/${componentIdentifier}`,
      null,
      getAccessToken,
      addToast,
      null,
      handleSuccess,
      history
    )
  }

  const getDeviations = async (libraryIdentifier) => {
    return get(
      `${process.env.REACT_APP_API_URI}v1/smarthazop/deviation`,
      getAccessToken,
      addToast,
      null,
      null,
      history
    )
  }

  const getEnablingConditions = async (libraryIdentifier, handleSuccess) => {
    return get(
      `${process.env.REACT_APP_API_URI}v1/smarthazop/library/${libraryIdentifier}/enabling-condition`,
      getAccessToken,
      addToast,
      null,
      handleSuccess,
      history
    )
  }

  const getEnablingCondition = async (libraryIdentifier, identifier, handleSuccess) => {
    return get(
      `${process.env.REACT_APP_API_URI}v1/smarthazop/library/${libraryIdentifier}/enabling-condition/${identifier}`,
      getAccessToken,
      addToast,
      null,
      handleSuccess,
      history
    )
  }

  const getEvent = async (libraryIdentifier, identifier) => {
    return get(
      `${process.env.REACT_APP_API_URI}v1/smarthazop/library/${libraryIdentifier}/event/${identifier}`,
      getAccessToken,
      addToast,
      null,
      null,
      history
    )
  }

  // ignoredLabels is unused, but for consistency between other autocomplete functions
  const getFluidImpactSuggestion = async (libraryIdentifier, ignoredLabels, query) => {
    return get(
      `${process.env.REACT_APP_API_URI}v1/smarthazop/fluid-impact/suggestion/${encodeURIComponent(
        query
      )}`,
      getAccessToken,
      addToast,
      null,
      null,
      history
    )
  }

  const getInitiatingEvent = async (libraryIdentifier, identifier) => {
    return get(
      `${process.env.REACT_APP_API_URI}v1/smarthazop/library/${libraryIdentifier}/initiating-event/${identifier}`,
      getAccessToken,
      addToast,
      null,
      null,
      history
    )
  }

  const getLossEvents = async (libraryIdentifier, handleSuccess) => {
    return get(
      `${process.env.REACT_APP_API_URI}v1/smarthazop/library/${libraryIdentifier}/loss-event`,
      getAccessToken,
      addToast,
      null,
      handleSuccess,
      history
    )
  }

  const getLossEvent = async (libraryIdentifier, identifier, handleSuccess) => {
    return get(
      `${process.env.REACT_APP_API_URI}v1/smarthazop/library/${libraryIdentifier}/loss-event/${identifier}`,
      getAccessToken,
      addToast,
      null,
      handleSuccess,
      history
    )
  }

  // ignoredLabels is unused, but for consistency between other autocomplete functions
  const getOperationSuggestion = async (libraryIdentifier, ignoredLabels, query) => {
    return get(
      `${process.env.REACT_APP_API_URI}v1/smarthazop/operation/suggestion/${encodeURIComponent(
        query
      )}`,
      getAccessToken,
      addToast,
      null,
      null,
      history
    )
  }

  const getRiskMatrix = async (libraryIdentifier, handleSuccess) => {
    return get(
      `${process.env.REACT_APP_API_URI}v1/smarthazop/library/${libraryIdentifier}/risk-matrix`,
      getAccessToken,
      addToast,
      null,
      handleSuccess,
      history
    )
  }

  const getSafeguards = async (libraryIdentifier, handleSuccess) => {
    return get(
      `${process.env.REACT_APP_API_URI}v1/smarthazop/library/${libraryIdentifier}/safeguard`,
      getAccessToken,
      addToast,
      null,
      handleSuccess,
      history
    )
  }

  const getSafeguard = async (libraryIdentifier, identifier, handleSuccess) => {
    return get(
      `${process.env.REACT_APP_API_URI}v1/smarthazop/library/${libraryIdentifier}/safeguard/${identifier}`,
      getAccessToken,
      addToast,
      null,
      handleSuccess,
      history
    )
  }

  const getScenarioMetricsDeviations = async (libraryIdentifier, params) => {
    return get(
      `${
        process.env.REACT_APP_API_URI
      }v1/smarthazop/library/${libraryIdentifier}/scenario/metrics/deviation?${params.toString()}`,
      getAccessToken,
      addToast,
      null,
      null,
      history
    )
  }

  const getScenarioMetricsFluidImpact = async (libraryIdentifier, params) => {
    return get(
      `${
        process.env.REACT_APP_API_URI
      }v1/smarthazop/library/${libraryIdentifier}/scenario/metrics/fluid-impact?${params.toString()}`,
      getAccessToken,
      addToast,
      null,
      null,
      history
    )
  }

  const getScenarioMetricsInitiator = async (libraryIdentifier, params) => {
    return get(
      `${
        process.env.REACT_APP_API_URI
      }v1/smarthazop/library/${libraryIdentifier}/scenario/metrics/initiator?${params.toString()}`,
      getAccessToken,
      addToast,
      null,
      null,
      history
    )
  }

  const getScenarioMetricsOperation = async (libraryIdentifier, params) => {
    return get(
      `${
        process.env.REACT_APP_API_URI
      }v1/smarthazop/library/${libraryIdentifier}/scenario/metrics/operation?${params.toString()}`,
      getAccessToken,
      addToast,
      null,
      null,
      history
    )
  }

  const getScenarios = async (libraryIdentifier, params, setInProgress, handleSuccess) => {
    return get(
      `${
        process.env.REACT_APP_API_URI
      }v1/smarthazop/library/${libraryIdentifier}/scenario?${params.toString()}`,
      getAccessToken,
      addToast,
      setInProgress,
      handleSuccess,
      history
    )
  }

  const getScenario = async (libraryIdentifier, identifier) => {
    return get(
      `${process.env.REACT_APP_API_URI}v1/smarthazop/library/${libraryIdentifier}/scenario/${identifier}`,
      getAccessToken,
      addToast,
      null,
      null,
      history
    )
  }

  const getScenarioSuggestion = async (
    libraryIdentifier,
    projectNumber,
    scenarioIdentifier,
    setInProgress
  ) => {
    return get(
      `${process.env.REACT_APP_API_URI}v1/smarthazop/library/${libraryIdentifier}/scenario/suggestion/${projectNumber}/${scenarioIdentifier}`,
      getAccessToken,
      addToast,
      setInProgress,
      null,
      history
    )
  }

  const getSearchSuggestion = async (libraryIdentifier, labels, query) => {
    const params = new URLSearchParams()
    labels.forEach((label) => params.append('searchLabels', label))
    return get(
      `${
        process.env.REACT_APP_API_URI
      }v1/smarthazop/library/${libraryIdentifier}/suggestion/${encodeURIComponent(
        query
      )}?${params.toString()}`,
      getAccessToken,
      addToast,
      null,
      null,
      history
    )
  }

  const saveLibrary = async (library, setInProgress, handleSuccess) => {
    return save(
      `${process.env.REACT_APP_API_URI}v1/smarthazop/library`,
      library,
      getAccessToken,
      addToast,
      setInProgress,
      handleSuccess
    )
  }

  const saveEnablingCondition = async (libraryIdentifier, data, setInProgress, handleSuccess) => {
    return save(
      `${process.env.REACT_APP_API_URI}v1/smarthazop/library/${libraryIdentifier}/enabling-conditions/`,
      data,
      getAccessToken,
      addToast,
      setInProgress,
      handleSuccess
    )
  }

  const connectEventToKoScenario = async (
    libraryIdentifier,
    eventIdentifier,
    data,
    setInProgress,
    handleSuccess
  ) => {
    return save(
      `${process.env.REACT_APP_API_URI}v1/smarthazop/library/${libraryIdentifier}/event/${eventIdentifier}/ko-scenario`,
      data,
      getAccessToken,
      addToast,
      setInProgress,
      handleSuccess,
      history
    )
  }

  const disconnectEventToKoScenario = async (
    libraryIdentifier,
    eventIdentifier,
    data,
    setInProgress,
    handleSuccess
  ) => {
    return deleteRequest(
      `${process.env.REACT_APP_API_URI}v1/smarthazop/library/${libraryIdentifier}/event/${eventIdentifier}/ko-scenario`,
      data,
      getAccessToken,
      addToast,
      setInProgress,
      handleSuccess,
      history
    )
  }

  const saveEvent = async (libraryIdentifier, data, handleSuccess) => {
    return save(
      `${process.env.REACT_APP_API_URI}v1/smarthazop/library/${libraryIdentifier}/event/`,
      data,
      getAccessToken,
      addToast,
      null,
      handleSuccess
    )
  }

  const saveInitiatingEvent = async (libraryIdentifier, data, handleSuccess) => {
    return save(
      `${process.env.REACT_APP_API_URI}v1/smarthazop/library/${libraryIdentifier}/initiating-event/`,
      data,
      getAccessToken,
      addToast,
      null,
      handleSuccess
    )
  }

  const saveLossEvent = async (libraryIdentifier, data, handleSuccess) => {
    return save(
      `${process.env.REACT_APP_API_URI}v1/smarthazop/library/${libraryIdentifier}/loss-event`,
      data,
      getAccessToken,
      addToast,
      null,
      handleSuccess
    )
  }

  const saveSafeguard = async (libraryIdentifier, data, handleSuccess) => {
    return save(
      `${process.env.REACT_APP_API_URI}v1/smarthazop/library/${libraryIdentifier}/safeguard/`,
      data,
      getAccessToken,
      addToast,
      null,
      handleSuccess
    )
  }

  const saveScenario = async (libraryIdentifier, data, handleSuccess) => {
    return save(
      `${process.env.REACT_APP_API_URI}v1/smarthazop/library/${libraryIdentifier}/scenario/`,
      data,
      getAccessToken,
      addToast,
      null,
      handleSuccess
    )
  }

  const moveScenario = async (
    libraryIdentifier,
    scenarioIdentifier,
    deviationIdentifier,
    handleSuccess
  ) => {
    return save(
      `${process.env.REACT_APP_API_URI}v1/smarthazop/library/${libraryIdentifier}/scenario/move`,
      { deviationIdentifier, scenarioIdentifiers: [scenarioIdentifier] },
      getAccessToken,
      addToast,
      null,
      handleSuccess
    )
  }

  const searchHazopDeviations = async (libraryIdentifier, params) => {
    return get(
      `${
        process.env.REACT_APP_API_URI
      }v1/smarthazop/library/${libraryIdentifier}/deviation/?${params.toString()}`,
      getAccessToken,
      addToast,
      null,
      null,
      history
    )
  }

  return {
    connectComponentToCriterion,
    disconnectComponentToCriterion,
    connectEventToKoScenario,
    disconnectEventToKoScenario,
    getDeviations,
    getEnablingConditions,
    getEnablingCondition,
    getEvent,
    getFluidImpactSuggestion,
    getInitiatingEvent,
    getLibraries,
    getLibrary,
    getLossEvents,
    getLossEvent,
    getOperationSuggestion,
    getRiskMatrix,
    getSafeguards,
    getSafeguard,
    getScenarioMetricsDeviations,
    getScenarios,
    getScenario,
    getScenarioSuggestion,
    getSearchSuggestion,
    getScenarioMetricsInitiator,
    getScenarioMetricsOperation,
    getScenarioMetricsFluidImpact,
    saveLibrary,
    saveEnablingCondition,
    saveEvent,
    saveInitiatingEvent,
    saveLossEvent,
    saveSafeguard,
    saveScenario,
    moveScenario,
    searchHazopDeviations
  }
}

export default useSmartHazopApi
