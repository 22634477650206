import React from 'react'

export default function BackgroundBalls({ styleClassed }) {
  return (
    <div className={`background-balls ${styleClassed || ''}`}>
      <div className="ellipse-1">&nbsp;</div>
      <div className="ellipse-2">&nbsp;</div>
      <div className="ellipse-3">&nbsp;</div>
    </div>
  )
}
