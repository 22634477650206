import React, { createContext, useCallback, useState } from 'react'

const ToastContext = createContext()

export default ToastContext

export function ToastContextProvider({ children }) {
  const [toasts, setToasts] = useState([])

  const addToast = useCallback(
    function (toast) {
      toast.key = toast.key || Math.random()
      setToasts((previousState) => [...previousState.filter((t) => t.key !== toast.key), toast])
      if (toast.type !== 'is-permanent') {
        setTimeout(
          () =>
            setToasts((previousState) => previousState.filter((item) => item.key !== toast.key)),
          6000
        )
      }
    },
    [setToasts]
  )

  return (
    <ToastContext.Provider value={addToast}>
      {children}
      <div className="notifications">
        {toasts.map((toast) => (
          <div className={`notification ${toast.level}`} key={toast.key}>
            <button
              type="button"
              className="button is-small close"
              aria-label="close"
              onClick={() =>
                setToasts((previousState) => previousState.filter((item) => item.key !== toast.key))
              }
            >
              <span className="iconify icon" data-icon="fa:times-circle" />
            </button>
            {toast.message}
          </div>
        ))}
      </div>
    </ToastContext.Provider>
  )
}
