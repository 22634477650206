import React from 'react'
import BackgroundBalls from '../components/BackgroundBalls'
import LoginWithMicrosoftButton from '../components/LoginWithMicrosoftButton'
import SignUpLink from '../components/SignUpLink'
import LoginWithUsernamePasswordButton from '../components/LoginWithUsernamePasswordButton'
import { isGordium } from '../utils/utils'

export default function LoginRequiredView() {
  return (
    <>
      <BackgroundBalls />
      <div>
        <div className="columns is-flex is-vcentered is-fullheight">
          <div className="column">&nbsp;</div>
          <div className="column is-7-desktop is-11-mobile is-11-tablet">
            <div className="card">
              <div className="card-content p-5">
                <div className="content">
                  <p className="title">{`Welcome to ${
                    isGordium() ? 'Gordium®' : 'SmartHAZOP®'
                  }`}</p>
                  <p className="subtitle">{`${
                    isGordium()
                      ? 'Transforming the HAZOP paradigm.'
                      : 'The best way to do your HAZOP studies!'
                  }`}</p>
                  <div className="buttons is-right mt-4 is-hidden-mobile">
                    <SignUpLink />
                    <LoginWithUsernamePasswordButton />
                    <LoginWithMicrosoftButton />
                  </div>
                  <div className="is-hidden-tablet is-flex is-flex-direction-column	">
                    <div className="mb-4 is-flex is-justify-content-center">
                      <LoginWithMicrosoftButton />
                    </div>
                    <div className="mb-4 is-flex is-justify-content-center">
                      <LoginWithUsernamePasswordButton />
                    </div>
                    <div className="is-flex is-justify-content-center is-vcentered is-aligned-center">
                      <SignUpLink />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column">&nbsp;</div>
        </div>
      </div>
    </>
  )
}
