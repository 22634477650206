import { multiFactor } from 'firebase/auth'
import { UserAuth } from '../contexts/AuthContext'

const useUserService = () => {
  const {
    isAuthenticated,
    signIn,
    logout,
    getAccessToken,
    user,
    person,
    fetchPerson,
    resourceRoles,
    isLoading,
    getLoginProvider
  } = UserAuth()

  const userHasRole = (role) => {
    return person && person.roles.includes(role)
  }

  const userHasRoles = (roles) => {
    return person && roles.some((i) => person.roles.includes(i))
  }

  const userHasRoleForResource = (role, resourceIdentifier) => {
    return (
      person &&
      (person.roles.includes(role) || resourceRoles.current[resourceIdentifier]?.includes(role))
    )
  }

  const userHasRolesForResource = (roles, resourceIdentifier) => {
    return (
      person &&
      roles.some(
        (i) => person.roles.includes(i) || resourceRoles.current[resourceIdentifier]?.includes(i)
      )
    )
  }

  const userIsFromTrustedProvider = () => {
    return (
      user.providerData?.length > 0 &&
      (user.providerData[0].providerId === 'microsoft.com' ||
        user.providerData[0].providerId === 'google.com')
    )
  }

  const isUserVerifiedOrTrusted = () => {
    if (user) {
      if (userIsFromTrustedProvider()) {
        return true
      }
      return user.emailVerified
    }
    return false
  }

  const isWithMFAOrTrusted = () => {
    if (user) {
      if (userIsFromTrustedProvider()) {
        return true
      } else if (multiFactor(user).enrolledFactors?.length > 0) {
        return true
      }
      return false
    }
    return false
  }

  return {
    user,
    person,
    fetchPerson,
    isAuthenticated,
    getAccessToken,
    userHasRole,
    userHasRoles,
    userHasRoleForResource,
    userHasRolesForResource,
    resourceRoles,
    isLoading,
    loginWithRedirect: signIn,
    logout,
    isUserVerifiedOrTrusted,
    isWithMFAOrTrusted,
    getLoginProvider
  }
}

export default useUserService
