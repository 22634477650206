import * as React from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import Profile from './Profile'
import LogoutButton from './LogoutButton'
import ShowLoginExpirePreventorButton from './ShowLoginExpirePreventorButton'
import { isGordium } from '../utils/utils'
import TrialInformation from '../views/billing/TrialInformation'
import GordiumLink from "./GordiumLink";

export default function Nav({ setForceShowLoginRefresh }) {
  const [navbarMenuActive, setNavbarMenuActive] = useState(false)

  const toggleNavbarMenuActive = () => {
    setNavbarMenuActive((prevState) => !prevState)
  }

  return (
    <nav id="navbar-main" className="navbar">
      <div className="navbar-brand">
        <Link className="navbar-item" to="/">
          {!isGordium() && <img src="/smartHAZOP_logo.svg" alt="smartHAZOP logo" />}
          {isGordium() && <img src="/logo-gordium-landscape.svg" alt="Gordium logo" />}
        </Link>
      </div>
      <div className="navbar-brand">
        <Link
          onClick={() => toggleNavbarMenuActive()}
          to="#"
          className="navbar-item is-hidden-desktop jb-navbar-menu-toggle"
          data-target="navbar-menu"
        >
          <>
            {!navbarMenuActive && (
              <span>
                <i className="iconify icon p-1" data-icon="mdi:dots-vertical" />
              </span>
            )}
            {navbarMenuActive && (
              <span>
                <i className="iconify icon p-1" data-icon="mdi:close" />
              </span>
            )}
          </>
        </Link>
      </div>
      <div
        className={`navbar-menu fadeIn animated faster ${navbarMenuActive ? 'is-active' : ''}`}
        id="navbar-menu"
      >
        <div className="navbar-end">
          <GordiumLink />
          <Profile setNavbarMenuActive={setNavbarMenuActive} />
          <ShowLoginExpirePreventorButton setForceShowLoginRefresh={setForceShowLoginRefresh} />
          <LogoutButton />
        </div>
      </div>
    </nav>
  )
}
