import { useRef } from 'react'
import { differenceInDays, fromUnixTime } from 'date-fns'

export default function getUniqueItemsBy(array, key) {
  return [...new Map(array.map((item) => [item[key], item])).values()]
}

export function isEmptyString(value) {
  return !value || value.length === 0
}

export function partition(array, isValid) {
  return array.reduce(
    ([pass, fail], elem) => {
      return isValid(elem) ? [[...pass, elem], fail] : [pass, [...fail, elem]]
    },
    [[], []]
  )
}

export function range(size, startAt = 0) {
  return [...Array(size).keys()].map((i) => i + startAt)
}

export function getRange(lower, upper, numberOfItems) {
  if (numberOfItems < 2) {
    return [lower]
  }
  const difference = lower - upper
  const increment = Math.ceil(difference / (numberOfItems - 1))
  return [
    upper,
    ...Array(numberOfItems - 2)
      .fill('')
      .map((_, index) => upper + increment * (index + 1)),
    lower
  ].reverse()
}

export function useFocus() {
  const htmlElRef = useRef(null)
  const setFocus = () => {
    htmlElRef.current?.focus()
  }

  return [htmlElRef, setFocus]
}

export function round(value, precision) {
  const multiplier = 10 ** (precision || 0)
  return Math.round(value * multiplier) / multiplier
}

export function calculateRiskLevel(value, severity) {
  const roundedValue = round(value, 1)
  if (!severity) {
    return ''
  }
  if (roundedValue > severity.intolerable) {
    return { level: 'is-danger', text: 'INTOLERABLE' }
  } else if (roundedValue > severity.undesirable) {
    return { level: 'is-warning', text: 'UNDESIRABLE' }
  } else if (roundedValue > severity.tolerable) {
    return { level: 'is-info', text: 'TOLERABLE' }
  }
  return { level: 'is-success', text: 'ACCEPTABLE' }
}

export function getTimezoneShort(timeZone) {
  return new Intl.DateTimeFormat('en-US', {
    timeZone,
    timeZoneName: 'short'
  })
    .formatToParts(new Date())
    .find((part) => part.type === 'timeZoneName').value
}

export function onlyUnique(value, index, array) {
  return array.indexOf(value) === index
}

export function numberOfMinutesSinceLogin(user) {
  const millisecondsInMinute = 1000 * 60
  return (Date.now() - user.metadata.lastLoginAt) / millisecondsInMinute
}

export const extraSafeguardCardClass = (safeguard, isLibrary = false) => {
  if (!isLibrary && safeguard.state === 'Rejected') {
    return 'rejectedSafeguard'
  }
  if (!safeguard.allActionsClosed && safeguard.state === 'Confirmed') {
    return `recommendedSafeguard ${safeguard.defaultCreditable ? '' : 'notCreditable'}`
  }
  if (isLibrary || safeguard.state === 'Confirmed') {
    return `confirmedSafeguard ${safeguard.defaultCreditable ? '' : 'notCreditable'}`
  }
  return `recommendedSafeguard withoutFeedback ${
    safeguard.defaultCreditable ? '' : 'notCreditable'
  }`
}

export const getNumberOfDigits = (probability) => {
  if (probability >= 3) {
    return probability - 2
  }
  return 0
}

export const calculatePercentageFromProbability = (probability) => {
  return probability.toFixed
    ? (10 ** -probability * 100).toFixed(getNumberOfDigits(probability))
    : ''
}

export const updateSortQuery = (history, query, column) => {
  if (query.get('sort') === column) {
    query.set('order', query.get('order') === 'asc' ? 'desc' : 'asc')
  } else {
    query.set('sort', column)
    query.set('order', column === 'impact' ? 'desc' : 'asc')
  }
  history.replace({ search: query.toString() })
}

export const getImpactWithRequiredTerms = (riskMatrix) => {
  let result = null
  Object.values(riskMatrix.matrix).forEach((category) => {
    category.forEach((impact) => {
      impact.impactTerms.forEach((impactTerm) => {
        if (impactTerm.required) {
          result = { ...impact, impactTerms: [impactTerm] }
        }
      })
    })
  })
  return result
}

export const getTrialDaysLeft = (trialData) => {
  return 14 - differenceInDays(new Date(), fromUnixTime(trialData?.trialStartedAt))
}

export const isTrialExpired = (trialData) => {
  return trialData && getTrialDaysLeft(trialData) <= 0
}

export const zeroPad = (num, places) => String(num).padStart(places, '0')

export const isGordium = () => document.getElementById('body').className === 'gordium'
