import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import BackgroundBalls from '../../components/BackgroundBalls'
import LoginWithMicrosoftButton from '../../components/LoginWithMicrosoftButton'
import Input from '../../components/Input'
import InputPassword from '../../components/InputPassword'
import { UserAuth } from '../../contexts/AuthContext'
import Icon from '../../components/Icon'
import { useFocus } from '../../utils/utils'
import Steps from '../../components/Steps'
import useUserService from '../../api/useUserService'
import useQuery from '../../utils/useQuery'

export default function SignUpView() {
  const history = useHistory()
  const query = useQuery()

  const { isLoading, createUser, user, sendVerificationEmail } = UserAuth()
  const { isUserVerifiedOrTrusted } = useUserService()
  const [usernamePasswordAccount, setUsernamePasswordAccount] = useState(false)
  const [error, setError] = useState('')
  const [inputRef, setInputFocus] = useFocus()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [emailVerificationSent, setEmailVerificationSent] = useState(false)

  useEffect(() => {
    setInputFocus()
    if (user && isUserVerifiedOrTrusted()) {
      history.push({
        pathname: '/person/me',
        search: query.toString()
      })
    }
  }, [user, user?.emailVerified])

  const register = async () => {
    setError('')
    try {
      await createUser(
        email,
        password,
        `${window.location.origin}/configure-mfa?${query.toString()}`
      )
      setEmailVerificationSent(true)
    } catch (e) {
      switch (e.code) {
        case 'auth/invalid-email':
          setError('Email address is not valid')
          break
        case 'auth/weak-password':
          setError('Password should be at least 6 characters')
          break
        case 'auth/email-already-in-use':
          setError(
            'This email address is already in use, if you previously logged in with your Microsoft account, try "Sign in with Microsoft"'
          )
          break
        default:
          setError(e.message)
          break
      }
    }
  }

  const handleOnKeyUp = (event) => {
    if (event.key === 'Enter') {
      register()
    }
  }

  const handleSendVerificationEmail = async () => {
    await sendVerificationEmail(window.location.href)
    setEmailVerificationSent(true)
  }

  return (
    <>
      <BackgroundBalls />
      {!isLoading && (
        <div>
          <div className="columns is-flex is-vcentered is-fullheight">
            <div className="column">&nbsp;</div>
            <div className="column is-8-desktop is-11-mobile is-8-tablet">
              <p className="title is-2 has-text-centered">
                On your way to a better HAZOP experience
              </p>
              <div className="columns">
                <div className="column">&nbsp;</div>
                <div className="column is-10">
                  <div className="card">
                    <div className="card-content p-5">
                      {!user && !isLoading && (
                        <>
                          <div className="content">
                            <p className="mt-4 has-text-centered">
                              Is your company using Microsoft?
                            </p>
                            <p className="mt-4 has-text-centered">
                              <LoginWithMicrosoftButton signup={true} />
                            </p>
                            <div className="columns is-flex is-vcentered pl-3 pr-3">
                              <div className="column">
                                <hr />
                              </div>
                              <div className="column is-2 has-text-centered">or</div>
                              <div className="column">
                                <hr />
                              </div>
                            </div>
                          </div>
                          {!usernamePasswordAccount && (
                            <div>
                              <p className="mt-4 has-text-centered">
                                <button
                                  type="button"
                                  className="button is-rounded"
                                  onClick={() => setUsernamePasswordAccount(true)}
                                >
                                  <Icon icon="ic:outline-password" />
                                  <span>Sign up with username password</span>
                                </button>
                              </p>
                            </div>
                          )}
                          {usernamePasswordAccount && (
                            <div>
                              <Steps activeStep={1} />
                              {error && <p className="has-text-warning">{error}</p>}
                              <div className="field">
                                <label className="label">Email</label>
                                <div className="control">
                                  <Input
                                    reference={inputRef}
                                    className="input"
                                    name="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="field">
                                <label className="label">Password</label>
                                <div className="control">
                                  <InputPassword
                                    className="input"
                                    name="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    onKeyUp={handleOnKeyUp}
                                  />
                                </div>
                              </div>
                              <div className="buttons is-right mt-4">
                                <button
                                  type="button"
                                  className="button is-ghost"
                                  onClick={() => history.push('/')}
                                >
                                  <Icon
                                    icon="ic:baseline-arrow-back"
                                    extraOuterClasses="has-text-primary-dark-blue"
                                  />
                                  <span>Back</span>
                                </button>
                                <button
                                  type="button"
                                  className="button is-rounded is-primary"
                                  onClick={register}
                                >
                                  <span>Create account</span>
                                </button>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                      {user && emailVerificationSent && (
                        <>
                          <Steps activeStep={2} />
                          <p>
                            Thank you for creating an account! A verification email has been sent to{' '}
                            {user?.email}. Please follow the instructions in the email to continue.
                          </p>
                        </>
                      )}
                      {user && !emailVerificationSent && (
                        <>
                          <Steps activeStep={2} />
                          <p>Hello user,</p>
                          <p className="mt-4">
                            your email address has not yet been verified. Please click the button
                            below to request a verification email. Follow the instruction in the
                            email to continue.
                          </p>
                          <div className="buttons is-right mt-4">
                            <button
                              type="button"
                              className="button is-rounded is-primary"
                              onClick={handleSendVerificationEmail}
                            >
                              <Icon icon="ic:outline-mail" />
                              <span>Verify email</span>
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="column">&nbsp;</div>
              </div>
            </div>
            <div className="column">&nbsp;</div>
          </div>
        </div>
      )}
    </>
  )
}
