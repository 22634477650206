import classes from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

/* eslint-disable react/require-default-props */
export function DatePickerDate({
  date,
  isEndDate,
  isInRange,
  isStartDate,
  isThisMonth,
  isToday,
  onClick
}) {
  return (
    <div
      className={classes('calendar-date', {
        'is-outside-month': !isThisMonth,
        'is-disabled': !onClick,
        'calendar-range': isInRange,
        'calendar-range-start': isInRange && isStartDate,
        'calendar-range-end': isInRange && isEndDate
      })}
    >
      <button
        type="button"
        className={classes('date-item', {
          'is-today': isToday,
          'is-active': isStartDate || isEndDate
        })}
        onClick={onClick ? () => onClick(date) : null}
      >
        {date.getDate()}
      </button>
    </div>
  )
}

DatePickerDate.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  isEndDate: PropTypes.bool,
  isInRange: PropTypes.bool,
  isStartDate: PropTypes.bool,
  isThisMonth: PropTypes.bool,
  isToday: PropTypes.bool,
  onClick: PropTypes.func
}

export default DatePickerDate
