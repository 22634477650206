import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import usePersonApi from '../api/usePersonApi'
import useInterval from '../hooks/useInterval'
import { UserAuth } from '../contexts/AuthContext'
import Gravatar from './Gravatar'

export default function Profile({ setNavbarMenuActive }) {
  const { isAuthenticated, person } = UserAuth()
  const { getOpenItemsForPerson } = usePersonApi()
  const [openItemsForPerson, setOpenItemsForPerson] = useState(null)
  const [delay, setDelay] = useState(300000)
  let timestampOnBlur = null

  const { listen } = useHistory()

  useEffect(
    () =>
      listen(() => {
        const el = document.activeElement

        if (el?.classList?.contains('navbar-item')) {
          el.blur()
        }
      }),
    [listen]
  )

  const refresh = async () => {
    if (isAuthenticated) {
      const eventResponse = await getOpenItemsForPerson()
      setOpenItemsForPerson(eventResponse)
    }
  }

  useInterval(() => {
    refresh()
  }, delay)

  useEffect(() => {
    refresh()
  }, [isAuthenticated])

  // User has switched back to the tab
  const onFocus = () => {
    setDelay(300000)
    if (timestampOnBlur != null && Date.now() - timestampOnBlur > 300000) {
      refresh()
    }
  }

  // User has switched away from the tab (AKA tab is hidden)
  const onBlur = () => {
    timestampOnBlur = Date.now()
    setDelay(28800000) // 8 hours
  }

  useEffect(() => {
    window.addEventListener('focus', onFocus)
    window.addEventListener('blur', onBlur)

    return () => {
      window.removeEventListener('focus', onFocus)
      window.removeEventListener('blur', onBlur)
    }
  }, [])

  return (
    isAuthenticated &&
    person && (
      <div className="navbar-item has-divider has-dropdown has-user-avatar is-hoverable">
        <Link
          to="/person/me"
          className="navbar-link is-arrowless"
          onClick={() => setNavbarMenuActive(false)}
        >
          <Gravatar person={person} />
          <div className="ml-4 is-user-name">
            <span>{person?.name || 'Please fill in your name'}</span>
          </div>
          <div>
            {openItemsForPerson?.totalItems > 0 && (
              <span className="tag is-danger ml-1 is-rounded is-small">
                {openItemsForPerson?.totalItems}
              </span>
            )}
          </div>
          <span className="iconify icon has-text-primary-dark-blue" data-icon="mdi:chevron-down" />
        </Link>
        <div className="navbar-dropdown">
          <Link className="navbar-item" to="/person/me" onClick={() => setNavbarMenuActive(false)}>
            Profile
            {openItemsForPerson &&
              openItemsForPerson.numberOfUnConfirmedRoles +
                openItemsForPerson.numberOfUnConfirmedWorksFor >
                0 && (
                <span className="tag is-danger ml-2 is-rounded is-small">
                  {openItemsForPerson.numberOfUnConfirmedRoles +
                    openItemsForPerson.numberOfUnConfirmedWorksFor}
                </span>
              )}
          </Link>
          <Link className="navbar-item" to="/action/" onClick={() => setNavbarMenuActive(false)}>
            Actions
            {openItemsForPerson && openItemsForPerson.numberOfOpenActions > 0 && (
              <span className="tag is-danger ml-2 is-rounded is-small">
                {openItemsForPerson.numberOfOpenActions}
              </span>
            )}
          </Link>
          <Link
            className="navbar-item"
            to="/conversation/"
            onClick={() => setNavbarMenuActive(false)}
          >
            Messages
            {openItemsForPerson && openItemsForPerson.numberOfUnreadMessages > 0 && (
              <span className="tag is-danger ml-2 is-rounded is-small">
                {openItemsForPerson.numberOfUnreadMessages}
              </span>
            )}
          </Link>
          <Link className="navbar-item" to="/help" onClick={() => setNavbarMenuActive(false)}>
            Help
          </Link>
        </div>
      </div>
    )
  )
}
