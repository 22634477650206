import { create } from 'zustand'

const useCustomerStore = create((set) => ({
  customer: null,
  study: null,
  trialData: null,
  setCustomer: (customer) => set({ customer }),
  setStudy: (study) => set({ study }),
  setTrialData: (trialData) => set({ trialData }),
  clearCustomerStore: () => set({ customer: null, trialData: null, study: null })
}))

export default useCustomerStore
