import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import BackgroundBalls from '../../components/BackgroundBalls'
import useBillingApi from '../../api/useBillingApi'
import EmptyContent from '../../components/EmptyContent'
import useQuery from '../../utils/useQuery'
import BillingAdminTabs from './BillingAdminTabs'
import BillingInvoicesStateTabs from './BillingInvoicesStateTabs'
import Icon from '../../components/Icon'

export default function BillingOverview() {
  const history = useHistory()
  const query = useQuery()
  const { getInvoices, updateInvoicePayments } = useBillingApi()

  const [invoices, setInvoices] = useState()
  const [inProgress, setInProgress] = useState()

  const refresh = () => {
    getInvoices(query.get('state'), setInProgress, setInvoices)
  }

  useEffect(() => {
    refresh()
  }, [query])

  function onClickAction(event, customerIdentifier, identifier) {
    const path = `/customer/${customerIdentifier}/billing/invoice/${identifier}`
    if (event.metaKey || event.ctrlKey || event.type === 'auxclick') {
      window.open(path, '_blank', 'noopener,noreferrer')
    } else {
      history.push(path)
    }
  }

  const checkEboekhouden = () => {
    updateInvoicePayments(setInProgress, refresh)
  }

  return (
    <div>
      <BackgroundBalls styleClassed="opacity-1" />
      <section className="hero is-hero-bar">
        <div className="columns is-vcentered mb-0">
          <div className="column is-8 hero-left">
            <p className="title">Billing</p>
          </div>
          <div className="column is-1 hero-center">
            <div className="hero-background">
              <div className="ellipse-1">&nbsp;</div>
            </div>
          </div>
          <div className="column is-3 hero-right">&nbsp;</div>
        </div>
      </section>
      <div className="section">
        {invoices && (
          <>
            <BillingAdminTabs />

            <div className="table-wrapper">
              <BillingInvoicesStateTabs />
              <table
                className={`table is-fullwidth is-striped is-bordered ${
                  invoices?.length > 0 ? 'is-hoverable' : ''
                }`}
              >
                {invoices?.length > 0 && (
                  <thead>
                    <tr>
                      <th className="width-8">Invoice date</th>
                      <th>Customer</th>
                      <th className="width-10">Invoice number</th>
                      <th className="width-12">PO number</th>
                      <th className="width-8 has-text-right">Total amount</th>
                    </tr>
                  </thead>
                )}
                <tbody>
                  {invoices?.map((invoice) => (
                    <tr
                      className="is-clickable"
                      key={invoice.identifier}
                      onClick={(e) =>
                        onClickAction(e, invoice.customerIdentifier, invoice.identifier)
                      }
                      onAuxClick={(e) =>
                        onClickAction(e, invoice.customerIdentifier, invoice.identifier)
                      }
                    >
                      <td>{invoice.invoiceDate}</td>
                      <td>{invoice.customerName}</td>
                      <td>{invoice.invoiceNumber}</td>
                      <td>{invoice.poNumber}</td>
                      <td className="has-text-right">&euro; {invoice.totalAmount.toFixed(2)}</td>
                    </tr>
                  ))}
                  {invoices?.length === 0 && (
                    <tr>
                      <td>
                        <EmptyContent text="Sorry, this box is empty for you. There are no actions found." />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </>
        )}
        {query.get('state') === 'NotPaid' && (
          <div className="buttons is-right mt-4">
            <button
              type="button"
              className="button is-rounded"
              onClick={checkEboekhouden}
            >
              <Icon icon="ic:outline-refresh" />
              <span>Check payment in e-boekhouden</span>
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
