import { useHistory } from 'react-router-dom'
import useToastContext from '../hooks/useToastContext'
import save, { get } from '../hooks/authenticated-fetch'
import useUserService from './useUserService'

const useBillingApi = () => {
  const history = useHistory()
  const addToast = useToastContext()
  const { getAccessToken } = useUserService()

  const generateInvoices = async (invoiceDate, setInProgress, handleSuccess) => {
    return save(
      `${process.env.REACT_APP_API_URI}v1/billing/generateInvoices?invoiceDate=${invoiceDate}`,
      null,
      getAccessToken,
      addToast,
      setInProgress,
      handleSuccess,
      history
    )
  }

  const getInvoice = async (identifier, setInProgress, handleSuccess) => {
    return get(
      `${process.env.REACT_APP_API_URI}v1/billing/invoice/${identifier}`,
      getAccessToken,
      addToast,
      setInProgress,
      handleSuccess,
      history
    )
  }

  const getInvoices = async (state, setInProgress, handleSuccess) => {
    return get(
      `${process.env.REACT_APP_API_URI}v1/billing/invoice?state=${state}`,
      getAccessToken,
      addToast,
      setInProgress,
      handleSuccess,
      history
    )
  }

  const getInvoiceAgreement = async (identifier, setInProgress, handleSuccess) => {
    return get(
      `${process.env.REACT_APP_API_URI}v1/billing/invoice-agreement/${identifier}`,
      getAccessToken,
      addToast,
      setInProgress,
      handleSuccess,
      history
    )
  }

  const getInvoiceAgreements = async (
    invoiceInterval,
    executionDate,
    sort,
    order,
    setInProgress,
    handleSuccess
  ) => {
    const params = new URLSearchParams()
    if (invoiceInterval) {
      params.append('invoiceInterval', invoiceInterval)
    }
    if (executionDate) {
      params.append('executionDate', executionDate)
    }
    if (sort) {
      params.append('sort', sort)
    }
    if (order) {
      params.append('order', order)
    }
    return get(
      `${process.env.REACT_APP_API_URI}v1/billing/invoice-agreement/${params.toString()}`,
      getAccessToken,
      addToast,
      setInProgress,
      handleSuccess,
      history
    )
  }

  const getInvoiceLines = async (state, sort, order, setInProgress, handleSuccess) => {
    const params = new URLSearchParams()
    if (state) {
      params.append('state', state)
    }
    if (sort) {
      params.append('sort', sort)
    }
    if (order) {
      params.append('order', order)
    }
    return get(
      `${process.env.REACT_APP_API_URI}v1/billing/invoice-line?${params.toString()}`,
      getAccessToken,
      addToast,
      setInProgress,
      handleSuccess,
      history
    )
  }

  const saveInvoice = async (invoice, setInProgress, handleSuccess) => {
    return save(
      `${process.env.REACT_APP_API_URI}v1/billing/invoice`,
      invoice,
      getAccessToken,
      addToast,
      setInProgress,
      handleSuccess,
      history
    )
  }

  const saveInvoiceLine = async (invoiceLine, setInProgress, handleSuccess) => {
    return save(
      `${process.env.REACT_APP_API_URI}v1/billing/invoice-line`,
      invoiceLine,
      getAccessToken,
      addToast,
      setInProgress,
      handleSuccess,
      history
    )
  }

  const updateInvoicePayments = async (setInProgress, handleSuccess) => {
    return get(
      `${process.env.REACT_APP_API_URI}v1/billing/invoice/rpc/update-invoice-payments`,
      getAccessToken,
      addToast,
      setInProgress,
      handleSuccess,
      history
    )
  }

  const publishInvoice = async (invoiceIdentifier, setInProgress, handleSuccess) => {
    return get(
      `${process.env.REACT_APP_API_URI}v1/billing/invoice/${invoiceIdentifier}/rpc/publish-invoice`,
      getAccessToken,
      addToast,
      setInProgress,
      handleSuccess,
      history
    )
  }

  return {
    generateInvoices,
    getInvoice,
    getInvoices,
    getInvoiceAgreement,
    getInvoiceAgreements,
    getInvoiceLines,
    saveInvoice,
    saveInvoiceLine,
    updateInvoicePayments,
    publishInvoice
  }
}

export default useBillingApi
