import {
  addDays,
  differenceInDays,
  endOfMonth,
  endOfWeek,
  format,
  isAfter,
  isBefore,
  isEqual,
  isSameMonth,
  isToday,
  isWithinInterval,
  startOfMonth,
  startOfWeek
} from 'date-fns'
import PropTypes from 'prop-types'
import React from 'react'
import DatePickerDate from './DatePickerDate'

const isValidDate = (date, minDate, maxDate) => {
  if (!minDate && !maxDate) return true
  if (minDate && maxDate) return isWithinInterval(date, { start: minDate, end: maxDate })
  if (maxDate) return isBefore(date, maxDate) || isEqual(date, maxDate)
  return isAfter(date, minDate) || isEqual(date, minDate)
}

/* eslint-disable react/require-default-props */
export function DatePickerCalendar({
  endDate,
  isRange,
  maxDate,
  minDate,
  onClickDate,
  onClickJump,
  startDate,
  visibleDate
}) {
  // the 7 days of the week (Sun-Sat)
  const labels = new Array(7)
    .fill(startOfWeek(visibleDate, { weekStartsOn: 1 }))
    .map((d, i) => format(addDays(d, i), 'EEE'))
  // first day of current month view
  const start = startOfWeek(startOfMonth(visibleDate), { weekStartsOn: 1 })
  // last day of current month view
  const end = endOfWeek(endOfMonth(visibleDate), { weekStartsOn: 1 })
  // get all days and whether they are within the current month and range
  const days = new Array(differenceInDays(end, start) + 1).fill(start).map((s, i) => {
    const theDate = addDays(s, i)
    const isThisMonth = isSameMonth(visibleDate, theDate)
    const isInRange = isRange && isWithinInterval(theDate, { start: startDate, end: endDate })
    // if not in range, no click action
    // if in this month, select the date
    // if out of this month, jump to the date
    // eslint-disable-next-line no-nested-ternary
    const onClick = !isValidDate(theDate, minDate, maxDate)
      ? null
      : isThisMonth
      ? onClickDate
      : onClickJump
    return {
      date: theDate,
      isToday: isToday(theDate),
      isStartDate: isEqual(startDate, theDate),
      isEndDate: isEqual(endDate, theDate),
      isThisMonth,
      isInRange,
      onClick
    }
  })
  return (
    <div className="calendar-container">
      <div className="calendar-header">
        {labels.map((day) => (
          <div key={day} className="calendar-date">
            {day}
          </div>
        ))}
      </div>
      <div className="calendar-body">
        {days.map((theDate) => (
          <DatePickerDate key={theDate.date.toString()} {...theDate} />
        ))}
      </div>
    </div>
  )
}

DatePickerCalendar.propTypes = {
  endDate: PropTypes.instanceOf(Date),
  isRange: PropTypes.bool,
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  onClickDate: PropTypes.func.isRequired,
  onClickJump: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date),
  visibleDate: PropTypes.instanceOf(Date)
}

export default DatePickerCalendar
